import React from 'react';

import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';
import { graphql } from 'gatsby';
import { Gallery } from '../components/Gallery';

const IndexPage = ({data}) => {
  return (<Layout fullMenu subPage>
      <Seo
        description=""
        keywords=""
        title="Svatební fotograf Besykdy"
      />
      <article id="main">
        <header id='family-photo' className='sub-page'>
          <StaticImage
            src='../assets/images/pic02.jpg'
            alt='Svatební fotograf Beskydy'
            placeholder='blurred'
            objectFit='cover'
            className='static-image'
            objectPosition='0% 0%'
          />
          <div className='header-text'>
            <h1>Svatební fotograf</h1>
            <p>Miluju svatby!</p>
          </div>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <h3>Svatby jsou nejvíc... :)</h3>
            <p>
              Užijte si svůj jedinečný den naplno a mně nechte zachytit dění kolem. Zvěčním váš smích, slzy i vše mezi tím.
              Vzpomínky jsou na celý život a bude mi ctí, když je společně pro vás zachytím v tom nejlepším světle.

            </p>

            <p>
              Fotografie "jak ze svatebního katalogu". Jedna vedle druhé, stejně strojené pózy a umělé usměvy. A když se zaměříte, objevite, že se jedná o různé lidské bytosti.
              Takovéto fotografie po mě prosím nechtějte.

            </p>

            <p>
              Každá dvojice budoucích novomanželů si svůj svatební den dlouho představuje a promýšlí dopředu lecjaký detail. A přesně takové budou i vaše svatební fotografie. Představy zachycené do detailu a přitom plné sponntání radosti a štěstí.

            </p>

            <hr />

            <div>
              <Gallery data={data} />
            </div>

            <hr />
            <h4>.</h4>
            <p>
              .
            </p>
          </div>
        </section>
      </article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query WeddingImages {
    allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "gallery/wedding"}}
     sort: {order: DESC, fields: name}
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 276
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`

export default IndexPage;
